<template>
  <div class="info-dialog">
    <el-dialog :visible.sync="infoDialog" width="800px" append-to-body>
      <div class="head-top">
        <div class="province screen-item">
          <div class="title">招生省份：</div>
          <div>{{ provinceMain.ProvinceName }}</div>
          <!-- <el-select v-model="query.ProvinceId" placeholder="请选择省份" @change="selectProvinceAndYear">
            <el-option v-for="(item, index) in provinceList" :key="index" :label="item.ProvinceName" :value="item.ID" />
          </el-select> -->
        </div>
        <div class="year screen-item">
          <div class="title">年份：</div>
          <el-select v-model="query.Year" placeholder="请选择年份" @change="selectProvinceAndYear">
            <el-option v-for="(item, index) in YearData" :key="index" :label="item.name" :value="item.value" />
          </el-select>
        </div>
      </div>
      <div class="head-bottom">
        <template v-if="!isNewExam">
          <div class="subject type-item">
            <div class="title">科类：</div>
            <el-radio-group v-model="query.SubjectType">
              <el-radio-button :label="1">文科</el-radio-button>
              <el-radio-button :label="2">理科</el-radio-button>
            </el-radio-group>
          </div>
        </template>
        <template v-else-if="isNewExam && newExamActive">
          <div class="new-exam type-item">
            <div class="title">科目：</div>
            <el-radio-group v-model="query.SelectSubject">
              <el-radio-button label="物理">物理</el-radio-button>
              <el-radio-button label="历史">历史</el-radio-button>
            </el-radio-group>
          </div>
        </template>
      </div>
      <div class="sure-button">
        <el-button class="button" @click="submit" type="primary" size="small">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from '@/api/config'
import { mapGetters } from "vuex";
import { getStore } from "@/utils/utils";
// import {WishProvinceAll, EnrollmentPlan} from "@/api/common";
// import {OrganizationProvince} from "@/api/student";

export default {
  name: "InfoDialog",
  data() {
    return {
      infoDialog: false,
      provinceList: [],
      schoolName: "",
      newPage: false,
      //新高考状态参数
      isNewExam: false,
      newExamActive: false,
      // 年份数据配置
      YearData: [
        { name: new Date().getFullYear(), value: new Date().getFullYear() + "" },
        { name: new Date().getFullYear() - 1, value: new Date().getFullYear() - 1 + "" },
        { name: new Date().getFullYear() - 2, value: new Date().getFullYear() - 2 + "" },
        { name: new Date().getFullYear() - 3, value: new Date().getFullYear() - 3 + "" }
      ],
      //parameters
      query: {
        ProvinceId: '',
        Year: new Date().getFullYear(),
        SelectSubject: '',
        SubjectType: '',
        ProvinceName: '',
        typeName: ''
      },

      provinceMain: {}
    }
  },
  computed: {
    ...mapGetters(["token"]),
  },
  mounted() {
    this.initialize()

  },
  methods: {
    // submit parameters
    submit() {
      // let temp = this.provinceList.find(item => item.ID === this.query.ProvinceId)
      // this.query.ProvinceName = temp.ProvinceName
      if (this.query.SubjectType) {
        this.query.SubjectType === 1 ? this.query.typeName = '文科' : this.query.typeName = '理科'
      } else if (this.query.SelectSubject) {
        this.query.typeName = this.query.SelectSubject
      } else {
        this.query.typeName = ''
      }
      this.$setStorage('enrollPlanInfo', this.query).then(_ => {
        this.infoDialog = false
        this.$globalEventBus.$emit('getEnrollPlanListData')
        if (this.newPage) {
          let routerUrl = this.$router.resolve({
            path: '/enroll-plan-info'
          })
          window.open(routerUrl.href)
        } else {
          this.$router.push('/enroll-plan-info')
        }
      })
    },
    //open dialog
    show(flag) {
      if (flag === 'new') {
        this.newPage = true
      } else {
        this.newPage = false
      }
      this.infoDialog = true
    },
    //选择省份
    selectProvinceAndYear() {
      this.$store.dispatch("user/judgeNewExam", {
        id: this.query.ProvinceId,
        year: this.query.Year,
      }).then((newExam) => {
        this.isNewExam = newExam.IsNewExam;
        if (newExam.IsNewExam) {
          this.$delete(this.query, "SubjectType");
          if (newExam.RuleList.length > 1) {
            this.newExamActive = true;
            this.$set(this.query, 'SelectSubject', "物理")
          } else {
            this.newExamActive = false;
            this.query.SelectSubject = "";
          }
        } else {
          this.$delete(this.query, "SelectSubject");
          this.$set(this.query, 'SubjectType', 1)
        }
      });
    },
    //初始化数据
    initialize() {
      // //获取省份列表
      // API.Public.ProvinceList().then((provinceData) => {
      //   this.provinceList = provinceData.data;
      //   //获取缓存数据
      //   this.$getStorage('enrollPlanInfo').then(res => {
      //     this.query.Year = res.Year
      //     this.query.SelectSubject = res.SelectSubject
      //     this.query.SubjectType = res.SubjectType
      //     //新高考
      //     this.judgeNewExamStatus();
      //   }).catch(_ => {
      //     this.judgeNewExamStatus();
      //   })
      // });

      // 缓存省份

      let temp = getStore('OrgProvince')
      if (!temp) {
        this.$message.error('未获取到省份数据！')
        return
      }
      this.provinceMain = JSON.parse(temp)
      this.query.ProvinceId = this.provinceMain.ProvinceId
      this.query.ProvinceName = this.provinceMain.ProvinceName
      this.$getStorage('enrollPlanInfo').then(res => {
        this.query.Year = res.Year
        this.query.SelectSubject = res.SelectSubject
        this.query.SubjectType = res.SubjectType
        //新高考
        this.judgeNewExamStatus();
      }).catch(_ => {
        this.judgeNewExamStatus();
      })
    },
    //新高考状态判定
    judgeNewExamStatus() {
      this.$store.dispatch("user/judgeNewExam", {
        id: this.query.ProvinceId,
        year: this.query.Year,
      }).then((newExam) => {
        this.isNewExam = newExam.IsNewExam;
        if (newExam.IsNewExam) {
          this.$delete(this.query, "SubjectType");
          if (newExam.RuleList.length > 1) {
            this.newExamActive = true;
            this.$set(this.query, 'SelectSubject', this.query.SelectSubject || "物理")
          } else {
            this.newExamActive = false;
            this.query.SelectSubject = "";
          }
        } else {
          this.$delete(this.query, "SelectSubject");
          this.$set(this.query, 'SubjectType', this.query.SubjectType || 1)
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
.head-top {
  margin-bottom: 2rem;
  display: flex;

  .screen-item {
    display: flex;
    align-items: center;
    margin-right: 5rem;

    .title {}
  }
}

.head-bottom {
  .type-item {
    display: flex;
    align-items: center;

    .title {
      margin-right: 1.7rem;
    }
  }
}

.sure-button {
  margin-top: 2rem;
  text-align: center;

  .button {
    width: 5rem;
    margin: auto;
  }
}
</style>
<style lang="less">
.screen-item .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.screen-item .el-select .el-input__inner:focus,.screen-item .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.el-select-dropdown__item.selected{
  color: rgba(var(--themecolor),0.75);
}
.el-radio-button__orig-radio:checked + .el-radio-button__inner{
  background-color: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
  box-shadow:none;
}
.el-radio-button__inner:hover{
  color: rgba(var(--themecolor),0.75);
}
.el-button--primary{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.el-button--primary:focus, .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
</style>