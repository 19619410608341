<template>
  <div class="main app-container">
    <div class="head">
      <div class="head-top">
        <div class="province screen-item">
          <div class="title">地区：</div>
          <div class="text">{{ infoData.ProvinceName }}</div>
        </div>
        <div class="year screen-item">
          <div class="title">年份：</div>
          <div class="text">{{ infoData.Year }}</div>
        </div>
        <div class="subjectType screen-item">
          <div class="text">{{ infoData.typeName }}</div>
        </div>
        <div class="screen-item">
          <el-button @click="modifyInfo" type="primary" size="mini">修改</el-button>
        </div>
      </div>
      <div class="head-bottom">
        <div class="box2">
          <condition-screen @screenParameters="screenParameters" @searchSchool="searchSchool"></condition-screen>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-table">
        <el-table :data="mainData.Data" border empty-text="暂无数据..."
          :header-cell-style="{ background: 'rgba(var(--themecolor),0.75)', color: '#ffffff' }" style="width: 100%" v-loading="contentLoading">
          <el-table-column align="center" label="院校" width="280">
            <template slot-scope="props">
              <div>
                {{ props.row.SchoolName }}
              </div>
              <div v-if="props.row.SchoolCode">
                院校代码：{{ props.row.SchoolCode }}
              </div>
              <!-- <div v-if="props.row.GroupCode">
                专业组代码：{{ props.row.GroupCode }}
              </div> -->
            </template>
          </el-table-column>
          <el-table-column align="center" label="专业名称">
            <template slot-scope="props">
              <div class="hover-color" @click="toSpecialityDetails(props.row)">
                {{ props.row.EnrollmentSpecialty }}
              </div>
              <div v-if="props.row.Code">
                代码：{{ props.row.Code }}
              </div>
              <div v-if="props.row.ExamCondition">
                {{ props.row.ExamCondition }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="PlanPersons" width="100" label="计划人数" />
          <el-table-column align="center" prop="LearningCost" width="160" label="学费" />
          <el-table-column align="center" prop="LearningYears" width="120" label="学制（年）" />
          <el-table-column align="center" prop="BatchName" width="200" label="批次" />
        </el-table>
      </div>
      <div v-if="authorization">
        <el-pagination v-show="mainData.Total > 0" style="text-align: center" :page-size="10" background
          layout="prev, pager, next" :total="mainData.Total" :current-page.sync="query.PageIndex"
          @current-change="turnPage" />
      </div>
      <div v-else>
        <member-limit></member-limit>
      </div>
    </div>
    <info-dialog ref="infoDialog" @getEnrollPlanListData="getEnrollPlanListData"></info-dialog>
  </div>
</template>

<script>
import API from '@/api/config'
import { mapGetters } from "vuex";
import { getStore } from '@/utils/utils'
// import { WishProvinceAll, EnrollmentPlan } from "@/api/common";

// import { OrganizationProvince } from "@/api/student";
// import { SchoolListBySchoolName } from "@/api/college";
import ConditionScreen from './local-components/ConditionScreen'
import InfoDialog from './local-components/InfoDialog'
import MemberLimit from '@/views/choose-wish/local-components/MemberLimit.vue';
import {areaData} from '@/utils/data';
export default {
  name: "enroll-plan",
  components: {
    ConditionScreen,
    InfoDialog,
    MemberLimit
  },
  data() {
    return {
      mainData: [],
      schoolName: "",
      infoData: {
        // Year: new Date().getFullYear()
      },
      //新高考状态参数
      isNewExam: false,
      newExamActive: false,

      contentLoading: false,
      //查询参数
      query: {
        SchoolId: null,
        ProvinceId: null,
        Year: new Date().getFullYear(),
        SubjectType: null,
        SelectSubject: null,
        PageIndex: 1,
        PageSize: 10,
        SchoolTagList: [],
        SchoolCategoryIdList: [],
        SchoolProvinceIdList: []
      },
      // 年份数据配置
      YearData: [
        { name: new Date().getFullYear(), value: new Date().getFullYear() + "" },
        { name: new Date().getFullYear() - 1, value: new Date().getFullYear() - 1 + "" },
        { name: new Date().getFullYear() - 2, value: new Date().getFullYear() - 2 + "" },
        { name: new Date().getFullYear() - 3, value: new Date().getFullYear() - 3 + "" }
      ],

      authorization: true,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  mounted() {
    this.initialize();
    this.$globalEventBus.$on('getEnrollPlanListData', _ => {
      this.getEnrollPlanListData()
    })

    // 权限
    this.$store.dispatch('user/allowMember', ['DataQuery']).then(flag => {
      this.authorization = flag
    })
  },
  methods: {
    //screenParameters
    screenParameters(parameters) {
      this.query.SchoolId = null
      this.query.SchoolProvinceIdList = areaData[parameters.ProvinceId]
      this.query.SchoolCategoryIdList = parameters.CategoryId
      this.query.SchoolTagList = parameters.Tag
      this.query.SchoolNature = parameters.SchoolNature || null
      this.query.PageIndex = 1
      this.getMainData().then(_ => {
        // callback()
      })
    },
    modifyInfo() {
      this.$refs.infoDialog.show()
    },
    //get data from search school
    searchSchool(id) {
      this.query.SchoolProvinceIdList = []
      this.query.SchoolCategoryIdList = []
      this.query.SchoolTagList = []
      this.query.SchoolNature = null
      this.query.SchoolId = id
      this.query.PageIndex = 1
      this.contentLoading = true
      this.getMainData().then(_ => {
        this.contentLoading = false
      })
    },
    //查看对应专业
    toSpecialityDetails(query) {
      let routerUrl
      if (query.SpecialtyCode.length === 4) {
        routerUrl = this.$router.resolve({
          path: "/specialty-list",
          query: {
            CategoryCode: query.SpecialtyCode,
            CategoryName: query.EnrollmentSpecialty,
          },
        });
      } else if (query.SpecialtyCode.length === 6) {
        routerUrl = this.$router.resolve({
          path: "/specialty-detail",
          query: { SpecialtyCode: query.SpecialtyCode },
        });
      }
      window.open(routerUrl.href, '_blank')
    },
    //翻页
    turnPage(page) {
      this.query.PageIndex = page;
      this.getMainData();
    },
    //选择年份 科目 招生方向
    selectParameters() {
      this.query.PageIndex = 1;
      this.getMainData();
    },
    //初始化数据
    initialize() {
      // this.getInfoParameters()

      let temp = getStore('OrgProvince')
      if (temp) {
        this.infoData.ProvinceName = JSON.parse(temp).ProvinceName
        this.query.ProvinceId = areaData[JSON.parse(temp).ProvinceId]
        this.getInfoParameters();

      } else {
        this.$message.error('未获取到省份信息！')
      }
    },
    //获取缓存数据
    getInfoParameters() {
      return new Promise(resolve => {
        this.$getStorage('enrollPlanInfo').then(res => {
          // this.infoData = res
          this.infoData = Object.assign(res, this.infoData)

          // this.query.ProvinceId = res.ProvinceId || null
          this.query.Year = res.Year
          this.query.SelectSubject = res.SelectSubject || null
          this.query.SubjectType = res.SubjectType || null
          this.getMainData().then(_ => {
            resolve()
          })
        }).catch(_ => {
          this.initializeProvince().then(_ => {
            this.getMainData().then(_ => {
              resolve()
            })
          })
        })
      })
    },
    //get initialize province data
    initializeProvince() {
      return new Promise(resolve => {
        //获取省份
        API.Public.ProvinceList().then(provinceData => {
          // this.infoData.ProvinceName = provinceData.data[0].ProvinceName
          // this.query.ProvinceId = provinceData.data[0].ID,
          this.$store.dispatch('user/judgeNewExam', {
            id: provinceData.data[0].ID,
            year: new Date().getFullYear()
          }).then(examStatus => {
            if (examStatus.IsNewExam) {
              this.$delete(this.query, "SubjectType");
              if (examStatus.RuleList.length > 1) {
                this.newExamActive = true;
                this.$set(this.query, 'SelectSubject', "物理")
                this.infoData.typeName = '物理'
                this.query.SelectSubject = '物理'
              } else {
                this.newExamActive = false;
                this.infoData.typeName = ''
                this.query.SelectSubject = null
              }
            } else {
              this.$delete(this.query, "SelectSubject");
              this.$set(this.query, "SubjectType", "1")
              this.infoData.typeName = "文科"
              this.query.SubjectType = "1"
            }
            resolve()
          })
        })
      })
    },
    getEnrollPlanListData() {
      return new Promise(resolve => {
        this.$getStorage('enrollPlanInfo').then(res => {
          this.$set(this.infoData, 'Year', res.Year)
          // this.$set(this.infoData, 'ProvinceName', res.ProvinceName)
          this.$set(this.infoData, 'typeName', res.typeName)
          // this.query.ProvinceId = res.ProvinceId
          this.query.Year = res.Year
          this.query.SelectSubject = res.SelectSubject
          this.query.SubjectType = res.SubjectType
          this.getMainData().then(_ => {
            resolve()
          })
        })
      })
    },
    //获取列表数据
    getMainData() {
      return new Promise(resolve => {


        API.Public.EnrollmentPlan(this.query).then((res) => {
          this.mainData = res.data;
          resolve()
        })
      })
    },
  },
};
</script>

<style scoped lang="less">
// @import "~@/styles/base.scss";
.main {
  padding: 3rem 0;

  .head {
    .head-top {
      margin-bottom: 2rem;
      display: flex;

      .screen-item {
        display: flex;
        align-items: center;
        margin-right: 3rem;

        .title {}

        .text {
          color: rgba(var(--themecolor),1);
        }
      }
    }

    .head-bottom {
      margin: 2rem 0;

      .box2 {}
    }
  }

  .content {
    .content-table {
      margin-bottom: 2rem;

      .hover-color {
        transition: all 0.2s linear;
        cursor: pointer;

        &:hover {
          color: rgba(var(--themecolor),1);
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-button--primary{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.el-button--primary:focus, .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.2);
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover{
  color: #FFF;
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .btn-next:hover, .el-pagination.is-background .btn-prev:hover, .el-pagination.is-background .el-pager li:hover{
  background-color: rgba(var(--themecolor),0.2);
}
</style>