<template>
  <div class="screen-container">
    <div class="box1">
      <div class="title">
        院校名称：
      </div>
      <el-autocomplete class="search-input" v-model="schoolName" :disabled="!authorization" value-key="SchoolName"
        :fetch-suggestions="querySearchSchool" :placeholder="authorization ? '请输入院校名称' : '开通会员可使用搜索'"
        :trigger-on-focus="false" @select="selectSchool"></el-autocomplete>
    </div>
    <div class="content">
      <div class="content-title">
        院校地区：
      </div>
      <div class="no-limit" :class="{ 'activate': query.ProvinceId.length === 0 }" @click="selectItem('province')">不限
      </div>
      <el-checkbox-group class="content-item" v-model="query.ProvinceId" size="mini">
        <el-checkbox-button v-for="(item, index) in mainData.ProvinceList" :label="item.ID" :key="index">
          {{ item.Name }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    <div class="content">
      <div class="content-title">
        院校类别：
      </div>
      <div class="no-limit" :class="{ 'activate': query.CategoryId.length === 0 }" @click="selectItem('category')">不限
      </div>
      <el-checkbox-group class="content-item" v-model="query.CategoryId" size="mini">
        <el-checkbox-button v-for="(item, index) in mainData.SchoolCategoryList" :label="item.ID" :key="index">
          {{ item.CategoryName }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    <div class="content">
      <div class="content-title">
        办学性质：
      </div>
      <div class="no-limit" :class="{ 'activate': query.SchoolNature === '' }" @click="selectItem('nature')">不限</div>
      <el-radio-group class="content-item" v-model="query.SchoolNature" size="mini">
        <el-radio-button v-for="(item, index) in mainData.SchoolNatureList" :label="item.Value" :key="index">
          {{ item.Name }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <!--<div class="content">
      <div class="content-title">
        办学层次：
      </div>
      <el-radio-group class="content-item" v-model="query.EducationLevel" size="mini">
        <el-radio-button label="">不限</el-radio-button>
        <el-radio-button v-for="(item, index) in mainData.educationLevel" :label="item.ID" :key="index">
          {{item.Name}}
        </el-radio-button>
      </el-radio-group>
    </div>-->
    <div class="content">
      <div class="content-title">
        特别属性：
      </div>
      <div class="no-limit" :class="{ 'activate': query.Tag.length === 0 }" @click="selectItem('tag')">不限</div>
      <el-checkbox-group class="content-item" v-model="query.Tag" size="mini">
        <el-checkbox-button v-for="(item, index) in mainData.SchoolTagList" :label="item.Value" :key="index">
          {{ item.Name }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    <div class="submit">
      <!--<el-button class="submit-button" @click="submitScreen" type="primary" plain size="small"
                 :loading="screenButtonLoading">{{screenButtonLoading?'搜索中...':'确认筛选'}}
      </el-button>-->
      <div class="submit-box">
        <el-button @mouseenter.native="buttonText = '开通会员'" @mouseleave.native="buttonText = '确认筛选'" @click="submitScreen"
          :type="authorization ? 'primary' : (buttonText == '开通会员' ? 'danger' : 'primary')" plain size="small">{{
            authorization ? '确认筛选' : buttonText }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
import { mapGetters } from 'vuex'
import { getStore } from '@/utils/utils'
// import { SchoolListBySchoolName } from "@/api/college";
export default {
  name: "ConditionScreen",
  props: {},
  data() {
    return {
      mainData: {},
      schoolName: "",
      // screenButtonLoading: false,
      buttonText: "确认筛选",
      query: {
        CategoryId: [],
        // EducationLevel: "",
        ProvinceId: [],
        SchoolNature: '',
        Tag: [],
      },


      authorization: true,
    }
  },
  computed: {
    ...mapGetters(["token", "SubjectType"]),
  },
  mounted() {
    this.initialize()

    this.$store.dispatch('user/allowMember', ['DataQuery']).then(flag => {
      this.authorization = flag
    })
  },
  methods: {
    //提交筛选参数
    submitScreen() {

      if (this.authorization) {
        this.schoolName = ""
        this.$emit('screenParameters', this.query, _ => {
          // this.screenButtonLoading = false
        })
      } else {
        this.$router.push('/openvip')
      }
    },
    //选择不限
    selectItem(type) {
      switch (type) {
        case 'province':
          this.query.ProvinceId = []
          break;
        case 'category':
          this.query.CategoryId = []
          break;
        case 'tag':
          this.query.Tag = []
          break;
        case 'nature':
          this.query.SchoolNature = ''
          break;
      }
    },
    //select the school
    selectSchool(item) {
      this.query.CategoryId = []
      this.query.ProvinceId = []
      this.query.Tag = []
      this.query.SchoolNature = ""
      this.$emit('searchSchool', item.ID)
    },
    //search school
    querySearchSchool(querySchoolName, cb) {
      let tempQuery = {
        SchoolName: querySchoolName,
        PageIndex: 1,
        PageSize: 99999
      }
      API.School.SchoolListBySchoolName(tempQuery).then(res => {
        cb(res.data.Data)
      })
    },
    //初始化数据
    initialize() {
      this.getScreenList()
    },
    async getScreenList() {
      this.mainData = (await API.School.BaseCondition()).data
    }
  }
}
</script>

<style scoped lang="less">
// @import "~@/styles/base.scss";

.screen-container {
  padding: 2rem;
  border: 1px solid var(--color-grey);
  background-color: #fff;

  .box1 {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .title {
      margin-right: 2rem;
    }

    .search-input {
      width: 504px;
    }
  }

  .content {
    margin-bottom: 1rem;
    display: flex;

    &-title {
      width: 6rem;
      font-size: var(--text-font-size);
      margin-right: 1rem;
    }

    .no-limit {
      box-sizing: border-box;
      width: 4.5rem;
      height: 1.5rem;
      line-height: 1;
      margin-right: 1rem;
      padding: .3rem 1.2rem;
      font-size: 12px;
      border: 1px solid var(--color-grey);
      color: #606266;
      cursor: pointer;
      border-radius: 1rem;
    }

    .activate {
      color: #ffffff;
      background-color: rgba(var(--themecolor),0.75);
      /*border: 1px solid transparent;*/
    }

    &-item {
      width: calc(100% - 10rem);

      ::v-deep .el-checkbox-button {
        margin: 0 1rem .5rem 0;

        .el-checkbox-button__inner {
          padding: .3rem 1.2rem;
          border: 1px solid var(--color-grey);
          border-radius: 1rem;
        }
      }

      ::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
        box-shadow: none;
      }

      ::v-deep .el-radio-button {
        margin: 0 1rem .5rem 0;

        .el-radio-button__inner {
          padding: .3rem 1.2rem;
          border: 1px solid var(--color-grey);
          border-radius: 1rem;
        }

        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
          box-shadow: none;
        }
      }
    }
  }

  .submit {
    text-align: center;

    &-button {
      width: 6rem;
    }
  }
}
</style>
<style lang="less">
.screen-container .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.el-checkbox-button.is-checked .el-checkbox-button__inner{
  background-color: rgba(var(--themecolor),0.75);
}
.el-checkbox-button__inner:hover,.el-radio-button__inner:hover{
  color: rgba(var(--themecolor),1);
}
.el-button--primary.is-plain{
  border-color: rgba(var(--themecolor),0.75);
  color: rgba(var(--themecolor),1);
}
.el-button--primary.is-plain:focus, .el-button--primary.is-plain:hover{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
</style>