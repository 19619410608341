<template>
    <div class="VIP-limit">
        <div class="box">

            <div class="item--1">
                {{ text_1 }} <span class="vip">{{ text_2}}</span> 查看更多
            </div>
            <div class="item--2">
                不浪费分数 上更好大学
            </div>
            <div class="item--3">
                <el-button class="button" @click="openVIP" type="danger">立即购买</el-button>
            </div>
        </div>

    </div>
</template>
  
<script>

import {mapGetters} from 'vuex'

export default {
    data() {
        return {
            
        }
    },
    props: {
        text_1: {
            type: String,
            default: '开通'
        },
        text_2: {
            type: String,
            default: '会员'
        }
    },
    computed: {
        ...mapGetters(['nameConfig'])
    },

    mounted() {
    },
    methods: {

        openVIP() {
            this.$store.dispatch('user/judgeLoginStatus').then(flag => {
                if (flag) {

                    let temp = Object.keys(this.nameConfig)
                    let tempItem = temp.find(item => {
                        if (this.nameConfig[item] === this.text_2) {
                            return true
                        }
                    })
                    if (tempItem) {
                        this.$router.push('/openvip?productCode='+tempItem)
                    } else {
                        this.$router.push('/openvip')
                    }
                } else {
                    this.$message({
                        message: '请先登录！',
                        showClose: true,
                        center: true
                    })
                    this.$router.push('/login')
                }
            })
        }
    }
}
</script>
  
<style scoped lang="less">
.VIP-limit {
    padding: 80px;
    background-color: rgba(var(--themecolor),0.1);

    text-align: center;

    .box {}

    .item--1 {
        font-size: 30px;

        .vip {
            color: rgba(var(--themecolor),1);
        }
    }

    .item--2 {
        margin: 10px auto;
        font-size: 16px;
    }

    .button {
        width: 250px;
        border-radius: 20px;
    }
}
</style>
<style lang="less">
.VIP-limit .el-button--danger{
    background-color: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.VIP-limit .el-button--danger:focus, .VIP-limit .el-button--danger:hover{
    background: rgba(var(--themecolor),1);
    color: #fff;
}
</style>